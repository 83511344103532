<template>
  <div>
    <div class="dashboard-container">
      <v-page-title
          class="_border-bottom"
          :primary="title.primary"
          :secondary="title.secondary"
      />
    </div>

    <v-under-construction/>
  </div>
</template>

<script>
import underConstruction from '@/services/errors/underConstruction'

export default {
  metaInfo () {
    return {
      title: `${this.title.primary} / ${this.title.secondary}`
    }
  },

  components: {
    'v-under-construction': underConstruction
  },

  data() {
    return {
      title: {
        primary: 'Segments',
        secondary: 'Experiments'
      }
    }
  }
}
</script>
